import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Esenses from "../../components/Portfolio/Esenses"

const EsensesPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Home" keywords={[`BMR`, `labels`]} />
    <Esenses />
  </Layout>
)

export default EsensesPage
